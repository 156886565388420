//font-weight 300 to FFDINWebProRegular
//font-weight 500 to FFDINWebProMedium
//font-weight 700 to FFDINWebProBold
// @import "~src/styles-font";
// .my-custom-component{  @extend  .font-b }
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

.font-r {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-r-i {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.font-m {
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.font-m-i {
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.font-b {
  font-family: 'Lato', sans-serif !important;
  font-weight: 700 !important;
  font-style: normal;
}

.font-b-i {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.font-r-important {
  font-family: 'Lato', sans-serif !important;
  font-weight: 400 !important;
}

.font-m-important {
  font-family: 'Lato', sans-serif !important;
  font-weight: 500 !important;
}

.font-lato {
  font-family: 'Lato', sans-serif !important;
  font-weight: 400 !important;
}

.font-lato-i {
  font-family: 'Lato', sans-serif !important;
  font-weight: 400 !important;
  font-style: italic;
}

.font-lato-b {
  font-family: 'Lato', sans-serif !important;
  font-weight: 700 !important;
}

.font-lato-b-i {
  font-family: 'Lato', sans-serif !important;
  font-weight: 700 !important;
  font-style: italic;
}

.font-inter {
  font-style: normal;
  font-weight: 500 !important;
  font-family: 'Inter-Regular', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
}

.font-gotham-r {
  // font-family: 'Gotham-Book', sans-serif;
  font-weight: 200;
  font-style: normal;
}

.font-gotham-r-i {
  // font-family: 'Gotham-BookIta', sans-serif;
  font-weight: 200;
  font-style: italic;
}

.font-gotham-l {
  // font-family: 'Gotham-Light', sans-serif;
  font-weight: 200;
  font-style: normal;
}

.font-gotham-l-i {
  // font-family: 'Gotham-LightIta', sans-serif;
  font-weight: 200;
  font-style: italic;
}

.font-gotham-m {
  // font-family: 'Gotham-Medium', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-gotham-m-i {
  // font-family: 'Gotham-MediumIta', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.font-gotham-b {
  // font-family: 'GothamBold', sans-serif !important;
  font-weight: 700 !important;
  font-style: normal;
}

.font-gotham-b-i {
  // font-family: 'Gotham-BoldIta', sans-serif;
  font-weight: 700;
  font-style: italic;
}
