.btn-primary {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: $primary-active;
    border-color: $primary-active;
  }
}

.btn-link {
  color: $primary;
  text-decoration: underline !important;

  &:hover {
    color: $primary-active;
  }
}

.btn-dark {
  @extend .btn-primary;
  background-color: $dark;
  border-color: $dark;

  &:hover {
    background-color: $dark;
    border-color: $dark;
  }
}

a {
  &.link {
    color: $primary !important;
    text-decoration: underline !important;

    &:hover {
      color: $primary-active !important;
      cursor: pointer;
      text-decoration: underline !important;
    }
  }
}

.text-danger {
  color: $danger !important;

  &:hover {
    color: $danger !important;
  }
}
