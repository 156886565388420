@font-face {
  font-family: Gotham-Black;
  src: url("Gotham-Black.otf") format("opentype");
  font-weight: 200;
  font-style: normal
}

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham-Book.otf") format("opentype");
  font-weight: 200;
  font-style: normal
}

@font-face {
  font-family: Gotham-BookIta;
  src: url("Gotham-BookIta.otf") format("opentype");
  font-weight: 200;
  font-style: normal
}

@font-face {
  font-family: GothamBold;
  src: url("Gotham-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: Gotham-BoldIta;
  src: url("Gotham-BoldIta.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Gotham-Light;
  src: url("Gotham-Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Gotham-LightIta;
  src: url("Gotham-LightIta.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham-Medium.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Gotham-MediumIta;
  src: url("Gotham-MediumIta.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}
