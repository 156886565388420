body {
  background-color: #040624;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0 auto;
}

html {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

b {
  @extend .font-b;
}

.table {

  td,
  th {
    background-clip: padding-box;
  }
}

.pointer {
  cursor: pointer;
  user-select: none !important;
}

.badge {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.modal-content {
  border-radius: 20px;
  box-sizing: border-box;
  color: #000000;
  background-color: #ffffff;
  box-shadow: 0 0 30px 1px #000000;

  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-header .close {
    outline: none;
  }
}

.custom-toast {
  @extend .modal-content;
  background-color: #ffffff;
  color: black;
  margin-top: 35px;
  background-image: initial !important;
  @extend .font-r;
  padding: 1rem;
  font-size: 1rem;
  display: inline-block;
  width: initial;
  max-width: 470px;

  .toast-fa-icon {
    &:before {
      content: '\f06a';
      color: black;
      display: inline-block;
      font: normal normal normal 16px/1 FontAwesome;
      font-size: 25px;
      vertical-align: sub;
      margin-right: 1rem;
    }
  }

  div.toast-ok {
    color: #3054c6;
    cursor: pointer;
    @extend .font-b;
    text-align: center;
    margin-top: 1rem;
  }
}

#toast-container {
  text-align: center;

  > div {
    //&:before {
    //  content: '';
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  bottom: 0;
    //  position: fixed;
    //  background: rgb(0, 0, 0);
    //  opacity: 0.6;
    //  display: block;
    //}

    &.custom-toast.toast-success {
      .toast-fa-icon {
        &:before {
          content: '\f058';
          color: #51a351;
        }
      }
    }

    &.custom-toast.toast-error {
      > div {
        text-align: center;
      }

      .toast-fa-icon {
        &:before {
          content: '\f06a';
          color: #bd362f;
        }
      }
    }

    &.custom-toast.toast-info {
      .toast-fa-icon {
        &:before {
          display: none;
        }
      }
    }

    &.custom-toast.toast-warning {
      .toast-fa-icon {
        &:before {
          content: '\f06a';
          color: #f89406;
        }
      }
    }
  }
}

.border-x {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.border-left {
  border-left: 1px solid black !important;
}

.border-right {
  border-right: 1px solid black !important;
}

.isSorted + .fa-question-circle-o {
  display: none;
}

.followHorse {
  color: #602e12;
  position: absolute;
  left: 9px;
  top: 7px;
  font-size: 30px;
}

.notFollowHorse {
  color: #e6e1de;
  position: absolute;
  left: 9px;
  top: 7px;
  font-size: 30px;
}

.stableConfrm {
  padding: 10px;
  position: absolute;
  border: 1px solid black;
  z-index: 100;
  background-color: white;
}

.program-root {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  top: -4px;
  margin-right: 5px;
}

.program {
  width: 30px;
  height: 30px;
  border: solid 1px #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 1px 0;
  font-size: 17px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  border-radius: 5px;
  @extend .font-b;
}

.pastPerformance {
  .program-root {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    margin-right: 5px;

    .program {
      width: 16px;
      height: 16px;
    }
  }
}

.program-1,
.program-1:hover {
  background-color: #df3131;
  color: #ffffff;
}

.program-2,
.program-2:hover {
  background-color: #ffffff;
  color: #07082d;
}

.program-3,
.program-3:hover {
  background-color: #3054c6;
  color: #ffffff;
}

.program-4,
.program-4:hover {
  background-color: #ffff02;
  color: #1a1a1a;
}

.program-5,
.program-5:hover {
  background-color: #177f1c;
  color: #ffffff;
}

.program-6,
.program-6:hover {
  background-color: #07082d;
  color: #ffff02;
}

.program-7,
.program-7:hover {
  background-color: #ff6602;
  color: #1a1a1a;
}

.program-8,
.program-8:hover {
  background-color: #ff9ece;
  color: #1a1a1a;
}

.program-9,
.program-9:hover {
  background-color: #04a0c5;
  color: #1a1a1a;
}

.program-10,
.program-10:hover {
  background-color: #72409e;
  color: #ffffff;
}

.program-11,
.program-11:hover {
  background-color: #adadad;
  color: #ff0600;
}

.program-12,
.program-12:hover {
  background-color: #0ee212;
  color: #000000;
}

.program-13,
.program-13:hover {
  background-color: #400b0b;
  color: #ffffff;
}

.program-14,
.program-14:hover {
  background-color: #60051f;
  color: #fffe07;
}

.program-15,
.program-15:hover {
  background-color: #cacaca;
  color: #000000;
}

.program-16,
.program-16:hover {
  background-color: #9bdfe2;
  color: #ff0600;
}

.program-17,
.program-17:hover {
  background-color: #08073a;
  color: #ffffff;
}

.program-18,
.program-18:hover {
  background-color: #0b2b02;
  color: #fffe07;
}

.program-19,
.program-19:hover {
  background-color: #0d228e;
  color: #df3131;
}

.program-20,
.program-20:hover {
  background-color: #f01e65;
  color: #fffe07;
}

.program-21,
.program-21:hover {
  background-color: #968fc3;
  color: #060639;
}

.program-22,
.program-22:hover {
  background-color: #10446d;
  color: #ffffff;
}

.program-23,
.program-23:hover {
  background-color: #bdd6cd;
  color: #003400;
}

.program-24,
.program-24:hover {
  background-color: #42502c;
  color: #ffffff;
}

.mto-program {
  .program,
  .program:hover {
    color: #ffffff;
    background: linear-gradient(to bottom, #79593D 50%, #5c5b38);
  }
}

.ae-program {
  .program,
  .program:hover {
    color: #ffffff;
    background: linear-gradient(to bottom, #6490ca 50%, #2A4858);
  }
}

.scratched-program {
  .program,
  .program:hover {
    color: #ffffff;
    background: linear-gradient(to bottom, #a3a3a3 50%, #818181);
  }
}

.horse-info {
  .program-root {
    width: 50px;
    height: 50px;

    .program {
      width: 50px;
      height: 50px;
      border: solid 1px #333333;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      font-size: 27px;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: center;
      @extend .font-b;
    }
  }
}

.legend {
  .program {
    height: 26px;
    width: 26px;
  }
}

.ng-dirty.ng-invalid {
  border-color: #dc3545;
}

.ng-dirty.ng-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.noclick {
  display: none;
}

.tooltip-question {
  color: #68747d;
  font-size: 15px;
}

.eliminator-modal .modal-dialog {
  max-width: 789px;
}

.mobileVisible {
  display: none;
}

.swipable-cards > div {
  display: none;
}

app-modal.text-info i {
  color: #1fa2d7;
}

.tabset {
  .nav.nav-tabs {
    position: relative;
    left: 96px;
  }

  .nav-item {
    .nav-link {
      color: white;
    }

    .nav-link.active {
      color: black;
    }
  }

  .tabset-body {
    background-color: #f6f6f6;
    position: fixed;
    bottom: 10px;
    right: 10px;
    top: 100px;
    left: 228px;
    overflow-y: auto;
    padding: 20px;

    .fa-times {
      cursor: pointer;
    }
  }
}

body {
  overflow-x: auto;
}

app-authenticated {
  position: relative;

  .card-header:first-child {
    border-radius: unset;
  }

  .race-detail-header {
    margin-left: 0;
  }

  .navbar-toggler {
    position: absolute;
    z-index: 5;
  }

  #collapseExample {
    z-index: 2;
    top: 0;
    margin-left: 0;
    left: 0;
    padding: 0;
    background-color: #f6f6f6;
    position: fixed;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.5);
  }
}

.accordion-layout {
  .active-day #panel-track-header {
    position: absolute;
    display: inline-block;
    width: 208px;
    bottom: 43px;
  }

  .active-day #panel-race-header,
  .active-track #panel-race-header {
    position: absolute;
    display: inline-block;
    width: 208px;
    bottom: 0;
  }

  .active-day {
    .card-body {
      .body {
        position: absolute;
        bottom: 86px;
        top: 43px;
      }
    }
  }

  .active-track {
    .card-body {
      .body {
        position: absolute;
        bottom: 42px;
        top: 86px;
      }
    }
  }

  .active-race {
    .card-body {
      .body {
        position: absolute;
        bottom: 0;
        top: 129px;
      }
    }
  }
}

.noclick.left-menu-is-show {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 6;
}

.mobile-race-detail-header {
  svg {
    height: 140px !important;
    width: 104px !important;
  }
}

.f-s-13 {
  font-size: 13px !important;
}

.f-s-14 {
  font-size: 14px !important;
}

.f-s-15 {
  font-size: 15px !important;
}

.f-s-16 {
  font-size: 16px !important;
}

.f-s-17 {
  font-size: 17px !important;
}

.f-s-18 {
  font-size: 18px !important;
}

.f-s-12 {
  font-size: 12px !important;
}

.f-s-10 {
  font-size: 10px !important;
}

.f-s-23 {
  font-size: 23px !important;
}

.f-s-24 {
  font-size: 24px !important;
}

.f-s-20 {
  font-size: 20px !important;
}

.f-s-19 {
  font-size: 19px !important;
}

.f-s-21 {
  font-size: 21px !important;
}

.f-s-22 {
  font-size: 22px !important;
}

.f-s-26 {
  font-size: 26px !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.pastPerformance {
  .tooltip {
    min-width: 250px;

    .tooltip-inner {
      max-width: 250px !important;
    }
  }
}

.joyride-step__arrow {
  display: none !important;
}

.joyride-step__container {
  .joyride-step__close {
    display: none;

    svg {
      display: none;
    }
  }

  .joyride-step__header {
    justify-content: center;

    .joyride-step__title {
      @extend .font-b;
      font-size: 15px;
      text-align: center;
      color: #1a1a1a;
    }
  }

  height: initial !important;
  width: initial !important;
  border-radius: 10px;
  // max-width: 400px !important;
  font-size: 15px;
  @extend .font-r;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
  color: #1a1a1a;
  box-shadow: 0 -2px 7px 3px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;

  .joyride-step__body {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 15px;
    text-align: center;
    color: #1a1a1a;
  }

  .joyride-button {
    font-size: 15px !important;
    @extend .font-b;
    color: #3054c6 !important;
    text-align: right !important;
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0 !important;
    font-weight: normal !important;
  }

  .joyride-step__counter {
    opacity: 0.65;
    font-size: 11px;
    text-align: center;
    color: #3e4a59;
  }
}

.help-button {
  display: inline-block;
  width: 38px;
  height: 38px;
  background-color: #00b6ff;
  border-radius: 38px;
  padding-top: 7px;
  padding-left: 5px;

  > div {
    width: 24px;
    height: 24px;
    margin-left: 3px;
    background: url(/assets/icons/intercom.svg) no-repeat;
  }
}

.icon-eye {
  height: 17px;
  width: 24px;
  background: url(/assets/icons/eye-new.svg) no-repeat;
  display: inline-block;
  background-size: contain;
  cursor: pointer;
}

.icon-eye-white {
  height: 17px;
  width: 24px;
  background: url(/assets/icons/eye-new.svg) no-repeat;
  display: inline-block;
  background-size: contain;
}

.current-horse {
  .icon-eye-white {
    height: 17px;
    width: 24px;
    background: url(/assets/icons/eye-new.svg) no-repeat;
    display: inline-block;
    background-size: contain;
  }
}

.icon-lock {
  height: 31px;
  background: url(/assets/icons/lock.svg) no-repeat;
  width: 26px;
  padding-top: 12px;
  font-size: 13px;
  @extend .font-b;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding-left: 3px;
}

.icon-unlock {
  height: 31px;
  background: url(/assets/icons/unlock.svg) no-repeat;
  width: 32px;
  padding-top: 12px;
  text-align: left;
  padding-left: 3px;
  font-size: 13px;
  @extend .font-b;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.icon-eye-slash {
  height: 18px;
  width: 28px;
  background: url(/assets/icons/eye-slash-new.svg) no-repeat;
  margin-top: 2px;
}

.icon-caret-up {
  height: 23px;
  width: 11px;
  display: inline-block;
  background: url(/assets/icons/caret-up.svg) no-repeat;
}

.icon-triangle-up {
  height: 12px;
  width: 12px;
  display: inline-block;
  background: url(/assets/icons/triangle-up.svg) no-repeat;
  background-size: contain;
}

.icon-triangle-down {
  height: 12px;
  width: 12px;
  display: inline-block;
  background: url(/assets/icons/triangle-down.svg) no-repeat;
  background-size: contain;
}

.icon-triangle-right {
  height: 12px;
  width: 12px;
  display: inline-block;
  background: url(/assets/icons/triangle-right.svg) no-repeat;
  background-size: contain;
}

app-home {
  .icon-triangle-right {
    height: 15px;
    width: 15px;
    display: inline-block;
    background: url(/assets/icons/triangle-right-white.svg) no-repeat;
  }
}

.icon-caret-down {
  height: 23px;
  width: 11px;
  display: inline-block;
  background: url(/assets/icons/caret-down.svg) no-repeat;
}

.icon-chevron-right {
  height: 11px;
  width: 11px;
  display: inline-block;
  background: url(/assets/icons/chevron-right.svg) no-repeat;
}

.icon-chevron-left {
  height: 11px;
  width: 11px;
  display: inline-block;
  background: url(/assets/icons/chevron-left.svg) no-repeat;
}

.icon-caret-none {
  height: 23px;
  width: 11px;
  display: inline-block;
  background: url(/assets/icons/caret-none.svg) no-repeat;
}

.icon-logo {
  height: 19px;
  width: 149px;
  background: url(/assets/icons/logo.svg) no-repeat;
  background-size: 149px;
  cursor: pointer;
}

.icon-logo1 {
  height: 34px;
  width: 30px;
  background: url(/assets/icons/home.svg) no-repeat;
  cursor: pointer;
}

.icon-question-mark {
  height: 16px;
  width: 16px;
  display: inline-block;
  background: url(/assets/icons/question-mark.svg) no-repeat;
}

.icon-rocket {
  height: 14px;
  width: 15px;
  display: inline-block;
  background: url(/assets/icons/rocket.png) no-repeat;
  background-size: 15px;
  vertical-align: bottom;
}

.gsr-header-name {
  .icon-triangle-up {
    background: url(/assets/icons/triangle-up-white.svg) no-repeat;
  }

  .icon-triangle-down {
    background: url(/assets/icons/triangle-down-white.svg) no-repeat;
  }
}

.responsive-width {
  max-width: 1366px;
  min-width: 1366px;
  width: 1366px;
}

.race-detail-header {
  .race-track {
    .link {
      color: #ffffff;
      font-size: 13px;
      text-decoration: underline;
      display: inline-block;
      padding: 10px;
      cursor: pointer;
      @extend .font-m;
    }

    .link {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 120px;
        height: 160px;
        top: -95px;
        left: -40px;
      }
    }
  }
}

.mobile-race-detail-header {
  .race-track {
    .link {
      text-decoration: underline;
    }
  }
}

.warning-tooltip {
  z-index: 102;
  min-width: 350px;
  background-color: white;
  position: absolute;
  padding: 15px 22px 5px;
  box-shadow: 0 -2px 7px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  top: 55px;
  line-height: 1.2;

  span {
    color: #1fa2d7;
    cursor: pointer;
  }

  .tooltip-header {
    font-size: 15px;
    @extend .font-b;
    line-height: 1.53;
    text-align: center;
    color: #1a1a1a;
  }

  .tooltip-body {
    margin-top: 10px;
    font-size: 15px;
    text-align: center;
    @extend .font-b;
    color: #1a1a1a;
    padding-bottom: 10px;
  }

  > .triangle {
    background-color: white;
    border-right-width: 0;
    border-bottom-width: 0;
    width: 10px;
    height: 10px;
    -moz-transform: rotate(45deg);
    /* Firefox */
    -o-transform: rotate(45deg);
    /* Opera */
    -webkit-transform: rotate(45deg);
    /* Safari y Chrome */
    filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=0.7071067811865476, M12=-0.7071067811865475, M21=0.7071067811865475, M22=0.7071067811865476);
    /* IE */
    position: absolute;
    left: 25px;
    top: -5px;
  }

  > .triangleBottom {
    left: 25px;
    bottom: -5px;
    top: initial;
  }
}

.invalid-feedback {
  position: relative;
}

.valid-feedback {
  position: relative;
}

#custom-modal-container {
  position: fixed;
  text-align: center;
  margin-left: -472px;
  min-width: 800px;
  min-height: 380px;
  left: 50%;
  background: white;
  top: 3%;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px black;
  z-index: 100;

  .carousel-inner {
    min-height: 221px;

    .carousel-item {
    }
  }

  .carousel-indicators {
    right: 60px;
    left: 60px;

    li {
      flex: 0 1 auto;
      width: 10px;
      height: 10px;
      display: block;
      background: #BBBBBB;
      border-top: none;
      border-bottom: none;
      border-radius: 10px;
      margin-right: 6px;
      margin-left: 6px;
    }
  }

  .carousel-control-prev, .carousel-control-next {
    bottom: 12px;
    align-items: end;
    color: black;
  }

  .carousel-control-prev {
    left: 60px;
  }

  .carousel-control-next {
    right: 60px;
  }

  .carousel-control-prev-icon {
    background-image: url("assets/icons/triangle-blue-left.svg");
  }

  .carousel-control-next-icon {
    background-image: url("assets/icons/triangle-blue-right.svg");
  }
}

.is-mobile-view.responsive-width {

  .intercom-launcher-frame,
  .intercom-lightweight-app, .intercom-namespace .intercom-dfosxs {
    display: none;
  }

  .btn-block {
    border-radius: 0;
  }

  .btn-success {
    color: #fff;
    border: none;
    background: linear-gradient(to bottom, #56dc22, #299b2c);

    &:hover,
    &:active {
      background: linear-gradient(to bottom, #56dc22, #299b2c);
    }
  }

  .btn-info {
    color: #fff;
    background-color: #00b6ff;
    border-color: #00b6ff;

    &:hover,
    &:active {
      background-color: #00b6ff;
      border-color: #00b6ff;
    }
  }

  max-width: initial;
  min-width: initial;
  width: initial;

  .responsive-width {
    max-width: initial;
    min-width: initial;
    width: 100%;
  }

  .logo-white {
    background: url(/assets/icons/logo-white.svg) no-repeat #040624;
    height: 37px;
    width: 220px;
    margin: 10px auto;
  }

  .login {
    top: 62px;
    left: 3%;
    right: 3%;
    width: 94%;
    bottom: 10px;
    border-radius: 9px;

    .form-section {
      width: 100% !important;
    }

    .img-login {
      margin-top: 25px !important;
      margin-bottom: 20px !important;
      max-height: 69px !important;
      background-size: 282px !important;
      height: 47px !important;
      width: 282px !important;
    }

    .btn-login {
      width: 100%;
    }
  }

  .signup {
    top: 62px;
    left: 3%;
    right: 3%;
    width: 94%;
    bottom: 10px;
    border-radius: 9px;
  }

  .warning-tooltip {
    left: 11px;
  }

  #custom-modal-container {
    width: 96%;
    top: 5%;
    left: 2%;
    margin: 0;
    min-width: 0;
    min-height: 0;
  }

  .program-root {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    top: -3px;
  }

  .program-root-1 {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    top: 0px;
  }

  .program {
    width: 30px;
    height: 30px;
    border: solid 1px #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 1px 0;
    font-size: 19px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    border-radius: 5px;
    @extend .font-b;
  }

  .icon-triangle-up {
    height: 13px;
    width: 13px;
    display: inline-block;
    background: url(/assets/icons/triangle-up.svg) no-repeat;
    background-size: contain;
    position: sticky;
    top: 14px;
  }

  .icon-triangle-right {
    height: 13px;
    width: 13px;
    display: inline-block;
    background: url(/assets/icons/triangle-right.svg) no-repeat;
    background-size: contain;
    position: sticky;
    top: 13px;
  }

  // .icon-lock {
  //   height: 31px;
  //   background: url(/assets/icons/lok.svg) no-repeat;
  //   width: 26px;
  //   padding-top: 12px;
  //   font-size: 13px;
  //   @extend .font-b;
  //   font-stretch: normal;
  //   line-height: normal;
  //   letter-spacing: normal;
  //   text-align: left;
  //   color: #000;
  //   padding-left: 3px;
  // }

  // .icon-unlock {
  //   height: 31px;
  //   background: url(/assets/icons/unlok.svg) no-repeat;
  //   width: 32px;
  //   padding-top: 12px;
  //   text-align: left;
  //   padding-left: 3px;
  //   font-size: 13px;
  //   @extend .font-b;
  //   font-stretch: normal;
  //   line-height: normal;
  //   letter-spacing: normal;
  //   color: #000;
  // }
}

.is-tablet-view.responsive-width {
  .intercom-launcher-frame {
    background: rgb(0, 113, 178, 0.5);
    transform: scale(0.8);
  }

  max-width: 1024px;
  min-width: 1024px;
  width: 1024px;

  .responsive-width {
    max-width: 1024px;
    min-width: 1024px;
    width: 1024px;
  }
}

.tab-nav-stat {
  z-index: 2;

  .ng-select.custom {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 10px 0 0 0;

    ng-dropdown-panel {
      min-width:90px;
      top: 57px;
      font-size:15px;
    }

    .ng-select-container {
      border: none;
      border-radius: 10px 0 0 0;
      background-color: #ffffff;
      box-shadow: none;
      padding: 5.5px 0 0 0 !important;
      margin: 0 !important;
      display: block;

      .ng-value-container {
        padding: 0;
        display: block;
        border-radius: 10px 0 0 0;

        .ng-input {
          cursor: pointer;

          input {
            @extend .font-b;
          }
        }
      }

      .ng-value-label {
        text-decoration: none;
        text-underline-offset: 0;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          border-radius: 0;
          background-color: #949191;
          bottom: 0;
          left: 0;
          //transform-origin: left;
          //transform: scaleX(0.25);
          transition: transform 0.3s ease-in-out;
          opacity: 0.5;
        }
      }

      .ng-value-container:hover {
        .ng-value-label {
          &:before {
            //transform-origin: left;
            //transform: scaleX(1);
            opacity: 1;
          }
        }
      }

      .ng-arrow-wrapper {
        display: none;
      }
    }
  }

  .mobile-select {
    .ng-select.custom {
      border-radius: 0;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      // background: linear-gradient(180deg, #040624 -43.78%, #244CCE 79.68%);
      background-color: #244bca;
      color: #ffffff;
      border-bottom: #979797 solid 1px;

      &.ng-option-marked {
        // background: linear-gradient(180deg, #040624 -43.78%, #244CCE 79.68%);
        background-color: #244bca;
        font-size: larger;
        color: #ffffff;
      }

      &.ng-option-selected {
        // background: linear-gradient(180deg, #040624 -43.78%, #244CCE 79.68%);
        color: #fff;
      }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: inherit;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
      // background: linear-gradient(180deg, #040624 -43.78%, #244CCE 79.68%);
      color: #ffffff;
      font-size: larger;
    }
  }
}

app-ticket-book {
  .ng-select {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      border-color: #808288;
      box-shadow: none;
    }

    .ng-dropdown-panel.ng-select-bottom {
      border-bottom-right-radius: 7px;
      border-bottom-left-radius: 7px;

      .ng-dropdown-panel-items {
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;

        .ng-option:last-child {
          border-bottom-right-radius: 7px;
          border-bottom-left-radius: 7px;
        }
      }
    }

    .ng-select-container {
      border-radius: 7px;
      font-size: 20px;
      color: #ffffff;
      height: 33px !important;
      min-height: 33px !important;
      background: #000000;
      border: 1px solid #808288;
      text-align: center;
      -moz-appearance: none;
      -webkit-appearance: none;
      box-shadow: none;
      padding: 1px 0 0 0 !important;
      margin: 0 !important;
      display: block;

      &.ng-select-focused {
        border: 1px solid #808288 !important;
      }

      .ng-value-container {
        padding: 0 !important;
        display: block;

        .ng-input {
          cursor: pointer;
          padding: 0;

          input {
            @extend .font-b;
          }
        }

        .ng-value {
          color: #ffffff;
        }
      }

      .ng-value-label {
      }

      .ng-arrow-wrapper {
        display: none;
      }
    }

    &.ng-select-opened {
      .ng-value-container {
        color: #ffffff;

        .ng-value {
          color: #ffffff;
        }
      }
    }
  }

  .mobile-datepicker-popup {
    .ngb-dp-day.disabled {
      .custom-day {
        opacity: 0.5;
        color: #6c757d;
      }
    }
  }
}

.mobile-table {
  .tab-nav-stat {
    .ng-select.custom {
      border: none !important;
      padding: 0 !important;
      margin: 0 !important;
      background-image: linear-gradient(to bottom, #ffffff, #e1dede 89%, #acacac);

      &.ng-select-single {
        .ng-select-container {
          .ng-value-container {
            .ng-value {
              color: #ffffff;
            }
          }
        }
      }

      .ng-select-container {
        border: none;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        padding: 0 0 0 0 !important;
        margin: 0 !important;
        display: inline-block;

        .ng-value-container {
          padding: 12px 2px 2px 2px;
          display: inline-block;

          .ng-input {
            cursor: pointer;
            height: 100%;

            input {
              @extend .font-m;
            }
          }
        }

        .ng-value-label {
          text-decoration: none;
        }

        .ng-arrow-wrapper {
          display: inline-block;
          cursor: pointer;
          position: absolute;
          right: 6px;
        }
      }
    }

    .ng-select .ng-arrow-wrapper .ng-arrow {
      border-width: 13px 8px 0;
      border-color: #fff transparent #fff;
    }

    .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
      border-width: 0 8px 13px;
    }

    .ng-select .ng-arrow-wrapper {
      width: 30px;
      top: 12px;
    }

    .ng-select.ng-select-single .ng-select-container {
      height: 56px;
      border: none;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
    }

    .ng-select .ng-select-container {
      min-height: 46px;
      box-shadow: none;
    }
  }

  .tab-content {
    background-color: #d8d8d8;
    margin-top: 32px;
  }

  .nav-tabs {
    position: fixed;
    left: 0;
    right: 0;

    .nav-link {
      border: 1px solid transparent;
      border-radius: initial;
      font-size: 11px;
      color: #0f0f0f;
      background-color: #afb6c3;
      padding-right: 0;
      padding-left: 0;

      &.active {
        background-color: #ffffff;
      }
    }
  }
}

.welcome-tooltip {
  min-width: 214px;

  img {
    width: 150px;
    text-align: center;
  }

  h2 {
    font-size: 27px;
    text-align: center;
    color: #232323;
  }

  p {
    font-size: 15px;
    text-align: center;
    color: #68747d;
  }

  .btn-info,
  .btn-info:focus,
  .btn-info:active {
    font-size: 15px;
    @extend .font-b;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    background-color: #3054c6 !important;

    img {
      width: 20px;
      display: inline-block;
      vertical-align: sub;
    }
  }
}

#AcceptUIBackground.show {
  opacity: 0.7;
  visibility: visible;
  z-index: 999998;
}

#AcceptUIBackground {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

#AcceptUIContainer {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.show + #AcceptUIContainer {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  min-height: 389px !important;
}

sup {
  top: -0.27em;
}

textarea {
  caret-color: #ffffff;
}

.icon-info-mark-white {
  height: 16px;
  width: 16px;
  background-size: 16px 16px;
  display: inline-block;
  background: url(/assets/icons/info-icon.svg) no-repeat;
}

.icon-question-mark-white {
  height: 16px;
  width: 16px;
  display: inline-block;
  background: url(/assets/icons/question-mark-white.svg) no-repeat;
}

.main-table {
  .link {
    cursor: pointer;

    .icon-question-mark {
      height: 14px;
      width: 14px;
      display: inline-block;
      background: url(/assets/icons/question-mark.svg) no-repeat;
      background-size: 14px;
      position: relative;
      top: 3px;
    }
  }
}

.ng-select .ng-select-container {
  border: none;
  box-shadow: none;
  color: #6b6b6b;
}

.btn-watch-video {
  width: 143px;
  height: 35px;
  border-radius: 9px;
  background-color: #1fa2d7;
  cursor: pointer;
  @extend .font-m;
  font-size: 16px;
  padding-top: 3px;
  text-align: center;
  color: #ffffff;
  position: relative;

  &:before {
    content: '';
    border-top: 8px solid #1fa2d7;
    border-bottom: 8px solid #1fa2d7;
    border-left: 16px solid white;
    height: 0;
    width: 0;
    position: relative;
    top: 3px;
    right: 5px;
    display: inline-block;
  }

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }
}

.marginTop12 {
  margin-top: 12% !important;
}

.marginTop3 {
  margin-top: 3% !important;
}

.gsr-header {
  .pointer {
    .ng-star-inserted {
      width: 10px;
      height: 10px;
      background-size: contain;
    }
  }
}

.small-sort-icons {
  cursor: pointer;

  > div {
    width: 8px;
    height: 8px;
    background-size: contain;
  }
}

.flex-even {
  flex: 1;
}

.height-40 {
  height: 40px;
}

.height-50 {
  height: 50px;
}

app-header {
  .greenDot {
    //border-radius: 8px !important;
    width: 8px !important;
    height: 8px !important;
  }

  .redDot {
    //border-radius: 8px !important;
    width: 8px !important;
    height: 8px !important;
  }
}

.ls-0 {
  letter-spacing: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.col {
  min-width: auto;
}

app-gsr-pro {
  div.gsr-column-name {
    > div.ng-star-inserted {
      position: absolute;
      bottom: 14px;
    }
  }

  .mobile-data {
    div.gsr-column-name {
      > div.ng-star-inserted {
        bottom: 3px;
      }
    }
  }
}

.mw-1024 {
  .gsr-footer {
    width: 1024px !important;

    &.bottom-66 {
      bottom: 0 !important;
    }
  }
}

.bet-now {
  display: inline-block;
  background: url(/assets/icons/bet-now.png) no-repeat;
  background-size: cover;
  width: 119px;
  height: 40px;
  margin-right: 15px;
  cursor: initial;
}

app-ticket-book {
  .overflow-mobile {
    padding-top: 11px;
    padding-bottom: 11px;

    .ticket-box {
      background: #ffffff;

      app-race-generated-ticket {
        .ticket {
          background: #ffffff;
          margin: 0;

          .ticket-book-title {
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
          }

          .ticket-book-buttons {
            border-bottom: solid 2px #262626;
            border-bottom-left-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
          }

          .second-line-wager {
            display: none;
          }
        }

        &.close-on-ticket-book {
          .ticket {
            > div.ticket-book-title {
              background: #ffffff !important;
              color: #000000 !important;
              border-radius: 0 !important;
              border-top-left-radius: 10px !important;
              border-top-right-radius: 10px !important;
              border-top: solid 2px #262626;
            }

            .ticket-cost-title,
            table,
            .ticket-book-buttons {
              display: none !important;
            }

            .second-line-wager {
              display: block !important;
            }

            .relative-wager {
              position: relative;
              bottom: 10px;
            }
          }
        }

        &.bottom-ticket {
          .ticket {
            > div.ticket-book-title {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              border-top: none;
              color: #000000 !important;
              border-bottom-left-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
              border-bottom: solid 2px #262626;
            }
          }
        }
      }

      &:first-child {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
        margin-top: -2px !important;

        app-race-generated-ticket {
          .ticket {
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
          }

          .ticket {
            margin-top: 0;
          }
        }
      }

      &:last-child {
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        margin-bottom: -2px !important;

        app-race-generated-ticket {
          .ticket {
            border-bottom-left-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
          }
        }
      }

      .is-ticket-book {
        border: none !important;
      }
    }
  }
}

app-predictor {
  .table-block {
    top: 266px !important;
    bottom: 5px !important;
    width: 1024px !important;
  }

  .tab-menu {
    margin-top: 54px !important;
  }

  .table-block.desktop {
    width: 1365px !important;
  }
}

app-trainers-edge,
app-trainers-search {
  .table-block {
    top: 448px !important;
    bottom: 45px !important;
    width: 1024px !important;
  }

  .tab-menu {
    margin-top: 16px !important;
  }

  .table-block.desktop {
    width: 1111px !important;
  }

  .table.header-table {
    tbody {
      tr {
        td.stats {
          &:nth-child(7) {
            background-color: #0a8910 !important;
            color: #ffffff !important;
          }

          &:nth-child(6) {
            background-color: #744e3e !important;
            color: #ffffff !important;
          }

          &:nth-child(8) {
            background-color: #5f5f5f !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

.show-chevron:after {
  content: '\f078';
  position: fixed;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  bottom: 52px;
  font-size: 30px;
  z-index: 100;
  left: 45%;
  color: #00000066;
}

.desktop-chevron.show-chevron:after {
  left: initial;
  margin-left: 62px;
  bottom: initial;
  top: 85vh;
}

.pb-65.show-chevron:after {
  bottom: 106px;
}

.page-link {
  color: #ffffff;

  &:hover {
    color: #000;
  }
}

ngx-intl-tel-input {
  width: 100%;

  .iti {
    width: 100%;
  }
}

.text-style-1 {
  color: #1fa2d7 !important;
}

.mb-15px {
  margin-bottom: 15px;
}

.mb-50px {
  margin-bottom: 50px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.pb-5px {
  padding-bottom: 5px;
}

.pt-3px {
  padding-top: 3px;
}

.pt-15px {
  padding-top: 15px !important;
}

.pb-20px {
  padding-bottom: 20px;
}

.pb-4px {
  padding-bottom: 4px !important;
}

.pt-9px {
  padding-top: 9px !important;
}

app-eliminator {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background: #1e3a93;
    color: #ffffff;
    border-bottom: #979797 solid 1px;

    &.ng-option-marked {
      background: #1e3a93;
      color: #ffffff;
    }

    &.ng-option-selected {
      background: #1e3a93;
      color: #ffffff;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background: #1e3a93;
    color: #ffffff;
  }
}

.color-red {
  color: red !important;
}

.color-white {
  color: #ffffff !important;
}

.horse-profile {
  .link span {
    text-decoration: none;
    text-underline-offset: 0;
  }

  .win {
  }
}

u {
  position: relative;
  text-decoration: none;
}

u::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  border-radius: 0;
  background-color: #949191;
  bottom: 0;
  left: 0;
  //transform-origin: left;
  //transform: scaleX(0.25);
  transition: transform 0.3s ease-in-out;
  opacity: 0.5;
}

u:hover::before {
  //transform-origin: left;
  //transform: scaleX(1);
  opacity: 1;
}

.fa-info.white {
  color: #fff;
}

.fa-info-circle {
  color: #afb6c3;
}

.fa-info.mlhelp {
  bottom: 5px;
  right: 7px;
  position: absolute;
}

.badge {
  font-weight: 500;
}

* {
  scrollbar-width: none;
  /* Firefox 64 */
  -ms-overflow-style: none;

  /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    /** WebKit */
    display: none;
  }
}

app-dashboard {
  .table-container {
    height: 300px;
    overflow-y: auto;
    border-radius: 10px;

    .table {
      margin: 0;
    }

    thead {
      tr:first-child {
        th {
          background: #244cce;
          color: #ffffff;
          height: 49px;
          padding: 0 0 12px 12px;
          font-size: 20px;
          border: none;
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }

      tr:nth-child(2) {
        th {
          border: none;
          text-align: center;
          background: #f2f2f3;
          color: #50545e;
          height: 42px;
          font-size: 14px;
          padding: 0 12px;
          position: sticky;
          top: 49px;
          z-index: 1;
        }
      }

      tr:nth-child(3) {
        th {
          border: none;
          text-align: center;
          background: #f2f2f3;
          color: #50545e;
          height: 42px;
          font-size: 14px;
          padding: 0 12px;
          position: sticky;
          top: 91px;
          z-index: 1;
        }
      }

      tr.buttons {
        th {
          border: none;
          padding: 0;
          text-align: left;
          background: #f2f2f3;
          color: #50545e;
          height: 42px;
          font-size: 14px;
          position: sticky;
          top: 49px;
          z-index: 1;
        }
      }
    }

    tbody {
      tr {
        td {
          height: 64px;
          background: white;
          padding: 0;
          vertical-align: inherit;

          .numberBox {
            margin-left: 5px;
            text-align: center;
            display: inline-block;
            height: 40px;
            width: 40px;
            border-radius: 5px;
            font-size: 20px;
            padding-top: 5px;
            border: 1px solid #000000;
          }
        }

        &:nth-of-type(even) {
          td {
            background: #f2f2f3;
          }
        }
      }
    }

    .badge {
      padding: 5px 7px;
      font-size: 16px;
    }
  }
}

.fa-info-circle {
  font-size: 16px !important;
}

.line-height-1_5 {
  line-height: 1.5;
}

.line-height-1_6 {
  line-height: 1.6;
}

.toggle-hw,
.toggle-ml {
  .ng-toggle-switch {
    font-size: 14px !important;
  }

  .ng-toggle-switch-core {
    background: linear-gradient(to bottom, #f8f8f8 43%, #afafaf) !important;
  }

  .ng-toggle-switch-button {
    background: linear-gradient(to bottom, #21275a 33%, #244ed3) !important;
  }
}

.toggle-ml {
  .ng-toggle-switch {
    font-size: 12px !important;
  }
}

.toggle-level {
  .toggle-hw {
    .ng-toggle-switch {
      font-size: 14px !important;
    }
  }
}

.print {
  display: block;
}

@media print {
  .noprint {
    display: none;
  }

  .print {
    display: block;
    top: 0;
  }

  .overflow {
    top: 0 !important;
  }
}

app-progeny-predictor {
  .table-block.desktop {
    top: 270px !important;
    bottom: 45px !important;
    width: 1365px !important;
  }
}

.width-125 {
  width: 125px;
}

app-modal-sire-dam {
  app-gsr {

    .table-block.desktop {
      top: 353px !important;
      bottom: 45px !important;
      width: 1252px !important;
      padding-left: 22px !important;
      padding-right: 4px !important;
      position: static !important;
    }

    .table th, .table td {
      padding: 6px 0 0 !important;
    }

    .tab-menu .tab-menu-item {
      height: 37px !important;
      font-size: 15px !important;
      border-top-right-radius: 5px !important;
      border-top-left-radius: 5px !important;

      .item-value {
        font-size: 14px !important;
        border-radius: 27px;
        height: 27px !important;
        width: 27px !important;
        top: 5px !important;
      }
    }
  }
}

#custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #000000d5;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1024px) and (max-width: 1364px) {
  body {
    zoom: 0.75;
  }
}

.ngb-dp-content {
  justify-content: center;
}
